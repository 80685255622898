.dialog {

    width: min(100%, max(500px))!important;
    padding-bottom: 0px!important;

}

.footer {

    width: min(100%, max(450px));
    padding: 12px 40px 100px 40px;
    box-sizing: border-box;
    position: relative;

}

.header {

    margin-bottom: 0px;

}

.code-container {

    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.code-container svg.qr {

    animation: appear .2s 1;

}

@keyframes appear {

    0% {
        transform: translateY(30px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }

}

.code-message {

    margin-bottom: 10px!important;
    font-weight: 600!important;
    font-size: 16px!important;

}