.container {
    background-color: transparent;
    transition: background-color .2s;
    position: relative;
    display: inline-block;
    width: fit-content;
}
.container .contentWrapper {
    opacity: 1;
    transition: opacity .2s;
    width: fit-content;
}
.container.loading .contentWrapper {
    opacity: 0;
}
.skeleton {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: transparent;
    overflow: hidden;
    border-radius: 15px;
}
.container.loading .skeleton {
    background-color: rgba(0,0,0,0.1);
}
.skeleton::after {
    content: '';
    background: linear-gradient(
        90deg,
        rgba(255,255,255, 0) 0,
        rgba(255,255,255, 0.2) 20%,
        rgba(255,255,255, 0.5) 60%,
        rgba(255,255,255, 0)
      );
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    transform: translateX(-100%);
    animation: shimmer 2s infinite;
    transition: opacity .2s;
    opacity: 0;
}
.container.loading .skeleton::after {
    opacity: 1;
}

@keyframes shimmer {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}