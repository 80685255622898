.device {

    display: flex;
    align-items: center;
    padding: 0px 30px;
    height: 120px;
    position: relative;
    column-gap: 30px;

}

.device::after {

    content: '';
    width: calc(100% - 60px);
    height: 1.5px;
    background-color: var(--border-primary);
    position: absolute;
    left: 30px;
    bottom: 0px;
    transform: translateY(50%);

}

.device:last-child:after {

    display: none;

}

.icon {

    width: 60px;
    min-width: 60px;
    height: 60px;
    background-color: rgba(0,0,0,0.1);
    border-radius: 50%;
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;
    overflow: hidden;

}

.icon .skeleton {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    background-color: transparent!important;
    display: inline-block
}

.icon .skeleton div {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}

.icon svg {

    fill: rgba(0,0,0,0.5);

}

.details {

    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

}

.details .name {

    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--text-secondary);
    margin-bottom: 2px;
    white-space: pre;

}

.details .timestampSkeleton {
    margin-top: 2px;
}

.details .timestamp {

    font-size: 14px;
    color: var(--text-primary);

}
 
.remove {

    width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color .1s;

}

.remove svg {

    width: 20px;
    height: 20px;
    stroke-width: 1.5px;
    stroke: rgba(0,0,0,0.5);
    pointer-events: none;
}

.remove:hover {

    background-color: rgba(0,0,0,0.1);
    cursor: pointer

}

.remove:active {

    background-color: rgba(0,0,0,0.15);

}

.this {

    display: flex;
    height: 20px;
    align-items: center;
    margin-top: 5px;

}

.this .check {

    width: 18px;
    min-width: 18px;
    height: 18px;
    background-color: var(--tint);
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    stroke: rgb(255,255,255);
    margin-right: 5px;

}

.this .check svg {

    width: 12px;
    stroke-width: 3;
    margin-bottom: -.5px;

}

.this .label {

    font-size: 12px;
    color: var(--tint);
    font-weight: 600;

}