:root {

    --tint: rgb(35, 121, 235);

    --text-primary: rgb(0,0,0);
    --text-secondary: rgb(100,100,100);
    --text-tertiary: rgb(170,170,170);

    --text-danger: rgb(235, 35, 68);

    --background-primary: rgb(255,255,255);
    --background-secondary: rgb(246,246,246);

    --button-primary: rgb(35, 121, 235);
    --button-primary-hover: rgb(36, 110, 209);
    --button-primary-active: rgb(28, 94, 184);
    --button-primary-text: rgb(255,255,255);

    --button-secondary: rgb(236, 236, 236);
    --button-secondary-hover: rgb(216, 216, 216);
    --button-secondary-active: rgb(200, 200, 200);
    --button-secondary-text: rgb(50,50, 50);

    --button-danger: rgb(220, 56, 60);
    --button-danger-hover: rgb(179, 45, 48);
    --button-danger-active: rgb(161, 40, 43);
    --button-danger-text: rgb(255,255,255);

    --input-border: rgb(236,236,236);
    --input-placeholder: rgb(150,150,150);
    --input-focused: rgb(35,121,235);
    --input-border-error: var(--text-danger);

    --checkbox-border: rgb(226,226,226);
    --checkbox-background: var(--background-primary);
    --checkbox-enabled: var(--input-focused);

    --qr-login-hover: rgba(0,0,0,0.1);
    --qr-login-active: rgba(0,0,0,0.15);

    --qr-login-background: rgb(255,255,255);
    --qr-login-border: rgb(205,205,205);

    --tooltip-background: rgb(75,75,75);
    --tooltip-text: rgb(255,255,255);

    --profile-border: var(--input-border);

    --sidebar-selected-tint: rgb(35, 121, 235, 1);
    --sidebar-selected-background: rgba(35, 121, 235, 0.1);
    --sidebar-selected-hover: rgba(35, 121, 235, 0.2);
    --sidebar-selected-active: rgba(35, 121, 235, 0.25);
    --sidebar-item-hover: rgba(0,0,0,0.05);
    --sidebar-item-active: rgba(0,0,0,0.1);

    --box-shadow-primary: 0px 0px 4px 0px rgba(0,0,0,0.15);

    --border-primary: rgba(0,0,0,0.1);

    --avatar-border-width: 2px;

    --box-shadow-profile: rgba(0,0,0,0.15);
    --profile-background: rgb(255,255,255);
    --profile-account-hover: rgba(0,0,0,0.05);
    --profile-account-active: rgba(0,0,0,0.1);
    --profile-divider: rgba(0,0,0,0.1);

    --slider-infill: rgb(35, 121, 235);

}