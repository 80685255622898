:root {
    --scrollbar-width: 17px;
}

.sidebar {

    width: 300px;;
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100vh;
    background-color: var(--background-primary);
    box-shadow: var(--box-shadow-primary);
    z-index: 200;

}

.sidebar .header {

    width: 100%;
    display: flex;
    height:80px;
    align-items: center;
    padding: 0px 10px;
    box-sizing: border-box;
    justify-content: center;
    column-gap: 12px;
    white-space: nowrap;

    transition: padding .25s;

}

.sidebar .header .logo {

    width: 28px;
    height: 28px;
    margin-left: -5px;

}

.sidebar .header .title {

    font-size: 19px;
    font-weight: 300;
    margin: 0px 12px 0px 0px;
    color: var(--text-secondary);

}

.sidebar .header .title b {

    font-weight: 600;
    padding: 0px 5px 0px 0px;
    color: var(--text-primary);

}

.sidebar .navigation {

    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: calc(100% - 80px);

}

.sidebar .navigation .items {

    width: 100%;
    padding: 0px 0px 0px 0px;
    margin: 10px 0px 0px 0px;

}

.sidebar .navigation .logout,
.sidebar .navigation .items .item {

    list-style: none;
    white-space: nowrap;
    width: 100%;
    position: relative;
    font-weight: 500;

}

.sidebar .navigation .items .item.selected a {

    background-color: var(--sidebar-selected-background);

}

.sidebar .navigation .items .item::before {

    content: '';
    position: absolute;
    left: 0px;
    top: 50%;
    width: 4px;
    background-color: var(--sidebar-selected-tint);
    height: 0px;
    transition: height .25s;
    transform: translateY(-50%);

}

.sidebar .navigation .items .item.selected::before {

    content: '';
    position: absolute;
    left: 0px;
    top: 50%;
    width: 4px;
    background-color: var(--sidebar-selected-tint);
    height: 100%;

}

.sidebar .navigation .logout,
.sidebar .navigation .items .item a {

    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
    padding: 18px 26px;
    font-size: 14px;
    user-select: none;
    cursor: pointer;
    transition: background-color .2s;
    color: var(--text-primary);

}

.sidebar .navigation .logout svg,
.sidebar .navigation .items .item svg {

    margin-right: 10px;

}

.sidebar .navigation .logout:hover,
.sidebar .navigation .items .item a:hover {

    background-color: var(--sidebar-item-hover);

}

.sidebar .navigation .logout:active,
.sidebar .navigation .items .item a:active {

    background-color: var(--sidebar-item-active);

}

.sidebar .navigation .items .item.selected a:hover {

    background-color: var(--sidebar-selected-hover);

}

.sidebar .navigation .items .item.selected a:active {

    background-color: var(--sidebar-selected-active);

}

.sidebar .navigation .items .item.selected a {

    color: var(--sidebar-selected-tint);
    font-weight: 600;

}

.sidebar .navigation .items .item.selected svg {

    fill: var(--sidebar-selected-tint);

}

.sidebar .navigation .logout svg,
.sidebar .navigation .items .item svg {

    width: 18px;
    height: 18px;

}

.sidebar .navigation .items .item.stroke-icon svg {

    fill: transparent;
    stroke: var(--text-primary);

}


.sidebar .navigation .items .item.stroke-icon.selected svg {

    stroke: var(--sidebar-selected-tint);

}

.sidebar .navigation .logout {

    display: flex;
    margin: 0px 0px 0px 0px;

}

.sidebar .navigation .logout .loader {

    width: 20px;
    height: 20px;
    margin: 0px 0px 0px auto;

}

.sidebar .navigation .logout .loader svg {

    stroke: rgba(0,0,0,1)

}

.sidebar .burger {

    width: 42px;
    height: 42px;
    margin-left: auto;
    display: none;
    margin-right: calc(-1 * var(--scrollbar-width));

}

@media (max-width: 900px){

    .sidebar {

        width: calc(100% - var(--scrollbar-width));
        height: 100%;
        background-color: rgba(0,0,0,0);
        -webkit-backdrop-filter: blur(0px);
        backdrop-filter: blur(0px);
        box-shadow: none;
        pointer-events: none;
        transition: background-color .25s, -webkit-backdrop-filter .25s, backdrop-filter .25s;

    }

    .sidebar.active {

        background-color: rgba(0,0,0,0.5);
        -webkit-backdrop-filter: blur(2px);
        backdrop-filter: blur(2px);
        pointer-events: initial;

    }

    .sidebar .header {

        align-items: center;
        justify-content: flex-start;
        padding: 0px 50px 0px 50px;
        height: 80px;
        background-color: var(--background-secondary);
        pointer-events: initial;
        transition: background-color .25s;

    }

    .sidebar.active .header {

        background-color: var(--background-primary);

    }

    .sidebar .navigation {

        transform: translateX(-100%);
        transition: transform .25s;
        pointer-events: none;
        height: 100%;
        width: 300px;
        background-color: var(--background-primary);

    }

    .sidebar.active {

        height: 100%;

    }

    .sidebar.active .navigation {

        display: flex;
        height: calc(var(--window-height, 100%) - 80px);
        position: absolute;
        bottom: 0px;
        transform: translateX(0%);
        pointer-events: initial;

    }

    .sidebar .burger {

        display: flex;

    }

}

@media (max-width: 550px){

    .sidebar .header {

        align-items: center;
        justify-content: flex-start;
        padding: 0px 30px 0px 30px;
        height: 70px;

    }

    .sidebar.active .navigation {

        display: flex;
        height: calc(var(--window-height, 100%) - 70px);
        position: fixed;
        bottom: var(--window-height, 100%) - 100%;
        transform: translateX(0%);

    }

    .sidebar .navigation .items {

        margin: 0px 0px 0px 0px;
    
    }
    

}


@media (max-width: 400px){


    .sidebar.active .navigation {

        width: 100%;

    }

    .sidebar.active {

        background-color: rgba(0,0,0,0);
        -webkit-backdrop-filter: blur(0px);
        backdrop-filter: blur(0px);
        pointer-events: initial;

    }

}