.slider {
    width: 100%;
    display: flex;
    align-items: center;
}

.slider .track {
    width: 100%;
    height: 8px;
    background-color: var(--border-primary);
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
}

.slider .track::before {
    content: '';
    width: var(--value, 50%);
    height: 100%;
    background-color: var(--slider-infill);
    position: absolute;
    left: 0px;
    top: 0px;
    border-radius: 10px 0px 0px 10px;
}

.slider .grabber {
    width: 12px;
    height: 28px;
    background-color: var(--background-secondary);
    border: 2px solid var(--border-primary);
    box-sizing: border-box;
    border-radius: 10px;
    cursor: e-resize;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.slider .grabber::after {
    content: '';
    width: 200%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 0px;
    transform: translateX(-50%);
}