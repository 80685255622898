.container {

    position: absolute;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    background-color: var(--background-secondary);
    padding: 0px 0px 0px 300px;
    box-sizing: border-box;
    transition: padding .25s, transform .25s, opacity .25s;

    animation: onLoad .25s 1  forwards;

}

@keyframes onLoad {
    0% {
        opacity: 0;
        transform: scale(1.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.container .profile {

    position: fixed;
    right: 30px;
    top: 30px;
    transform: translateY(-30%);

}

.container .content {

    display: flex;
    overflow: auto;
    height: 100vh;
    padding: 60px 100px;
    flex-direction: column;
    box-sizing: border-box;
    transition: padding .25s;
    position: absolute;
    top: 0px;
    width: calc(100% - 300px);

}

@media (max-width: 1100px) {

    .container .content {

        padding: 60px 30px;

    }

}

@media (max-width: 900px) {

    .container {

        padding: 0px 0px 0px 0px;

    }

    .container .content {

        padding: 80px 30px 60px 30px;
        width: 100%;

    }

}

@media (max-width: 530px) {

    .container {

        padding: 0px 0px 0px 0px;

    }

    .container .content {

        padding: 70px 30px 60px 30px;

    }

}

.container .content.transition-enter {
    opacity: 0;
    transform: translateY(30px);
}

.container .content.transition-enter-active {
    opacity: 1;
    transform: translateY(0px);
    transition: transform .3s, opacity .3s;
    transition-delay: .05s;
    pointer-events: none;
}

.container .content.transition-exit {
    opacity: 1;
    transform: translateY(0px);
}

.container .content.transition-exit-active {
    opacity: 0;
    transform: translateY(-30px);
    transition: transform .15s, opacity .15s;
    pointer-events: none;
}