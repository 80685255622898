.section {

    background-color: var(--background-primary);
    max-width: 1000px;
    margin-top: 40px;
    padding: 0px 0px;
    box-sizing: border-box;
    box-shadow: var(--box-shadow-primary);
    border-radius: 10px;

}

.label {

    font-size: 16px;
    color: var(--text-secondary);
    font-weight: 500;
    margin-bottom: -40px;
    margin-top: 40px;

}