.container {

    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

}

.wrapper {

    display: flex;
    flex-direction: column;
    text-align: center;

}

.wrapper svg {

    width: 400px;
    margin: -25% 0px 40px 0px;

}

.wrapper .code {

    font-size: 80px;
    display: block;
    font-weight: 500;

}

.wrapper .title {

    font-size: 34px;
    display: block;
    padding: 0px 0px 50px 0px;
    position: relative;
    font-weight: 400;

}

.wrapper .title::after {

    content: '';
    display: block;
    position: absolute;
    left: 50%;
    bottom: 25px;
    background-color: gray;
    width: 50px;
    height: 1px;
    transform: translate(-50%, -50%)
}

.wrapper .message {

    font-size: 18px;
    display: block;

}