.wrapper {
    font-size: 0px;
}

.item {

    padding: 34px 30px;
    box-sizing: border-box;
    position: relative;

}

.item::after {

    content: '';
    width: calc(100% - 60px);
    height: 1.5px;
    background-color: var(--border-primary);
    position: absolute;
    left: 30px;
    bottom: 0px;
    transform: translateY(50%);

}

.wrapper:last-child .item::after {

    display: none;

}

.label {

    font-size: 11px;
    font-weight: 600;
    margin-bottom: 7px;
    color: var(--text-secondary);
    text-transform: uppercase;

}

.value {

    font-size: 16px;
    font-weight: 400;
    color: var(--text-primary);

}