.header-1 {

    font-weight: 600;
    letter-spacing: .5px;

}

.no-bottom-margin {

    margin-bottom: 0px;

}