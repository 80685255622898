.container {

    position: relative;
    min-width: 0px;
    width: 100%;
    overflow: visible;
    margin: 0px 0px 20px 0px;
    flex: 1;

}

.container .wrapper {

    width: 100%;
    position: relative;
    overflow: visible;

}

.container .wrapper input {

    width: 100%;
    border: 0px solid transparent;
    outline: none;
    border: 2px solid var(--input-border);
    border-radius: 8px;
    padding: 16px 18px;
    font-size: 16px;
    box-sizing: border-box; 
    transition: border .2s;

}

.container .wrapper .placeholder {

    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%) translateX(-5px);
    font-size: 16px;
    pointer-events: none;
    color: var(--input-placeholder);
    transition: top .2s, font-size .2s, color .2s;
    padding: 0px 5px;
    background-color: var(--background-primary);
    user-select: none;

}

.container.focused .wrapper .placeholder,
.container.not-empty .wrapper .placeholder {

    top: 0%;
    font-size: 12px;

}

.container.focused .wrapper .placeholder {

    color: var(--input-focused);

}

.container.error .wrapper .placeholder {

    color: var(--text-danger);

}

.container.focused .wrapper input {
 
    border: 2px solid var(--input-focused);

}

.container.error .wrapper input {

    border: 2px solid var(--input-border-error);

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.container .error {

    display: flex;
    justify-content: flex-start;
    transition: opacity .2s, max-height .1s;
    opacity: 0;
    max-height: 0px;

}

.container .error.visible {

    opacity: 1;
    max-height: 20px;

}

.container .error .label {

    font-size: 14px;
    margin: 3px 0px 0px 0px;
    font-weight: 600;
    color: var(--text-danger);
    transition: opacity .2s, max-height .05s;

}

input:-webkit-autofill {
  background-color: transparent !important;
  background-image: none !important;
  color: inherit !important;
}

input:-moz-autofill {
  background-color: transparent !important;
  background-image: none !important;
  color: inherit !important;
}