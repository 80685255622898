body {

    overflow: hidden;

}

.container {

    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    align-items: center;
    justify-content: center;
    transform-style: preserve-3d;

}

.container .wrapper {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    max-width: 400px;

}

.container .logo {

    width: 40px;
    height: 40px;
    object-fit: contain;
    user-select: none;
    pointer-events: none;

}

.container .title {

    font-weight: 500;
    margin: 20px 0px 12px 0px;

}

.container .subtitle {

    font-size: 16px;
    text-align: center;
    line-height: 30px;
    padding: 0px 32px;
    box-sizing: border-box;
    margin: 0px 0px 40px 0px;
    color: var(--text-secondary);

}

.container .subtitle.slim-margin {

    margin: 0px 0px 20px 0px;

}

.container .button {

    margin: 16px 0px 0px 0px;
    width: 100%;
    box-sizing: border-box;
    flex: 1;

}

.container form {

    display: flex;
    justify-content: stretch;
    align-items: center;
    flex-direction: column;
    width: 310px;

}

.container .error-uri,
.container .decline,
.container .sign-in,
.container .sign-up {

    font-size: 16px;
    text-align: center;
    line-height: 30px;
    padding: 0px 32px;
    box-sizing: border-box;
    margin: 24px 0px 0px 0px;
    font-size: 14px;
    color: var(--text-secondary);

}

.container .error-uri {

    margin: 0px;

}

.container .decline {

    margin: 16px 0px 0px 0px;

}

.container .error-uri .clickable,
.container .decline .clickable,
.container .sign-in .clickable,
.container .sign-up .clickable {

    padding: 0px 0px 0px 7px;
    font-weight: 600;
    cursor: pointer;
    color: var(--text-primary);
    user-select: none;

}

.container .password-extra .forgot-password {

    cursor: pointer;
    user-select: none;
    color: var(--text-tertiary);
    text-decoration: none;
}

.container .password-extra.single-item {

    justify-content: right;

}

.container .error-uri .clickable:hover,
.container .permissions .permission:hover .label,
.container .decline .clickable:hover,
.container .password-extra .forgot-password:hover,
.container .sign-in .clickable:hover,
.container .sign-up .clickable:hover {

    text-decoration: underline;
    text-decoration-thickness: 2px;

}

.container .password-extra {

    width: 100%;
    margin: -4px 0px 16px 0px;
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    color: var(--text-tertiary);
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.container .qr-login {

    width: 38px;
    height: 38px;
    position: absolute;
    top: 100%;
    transform: translateY(6px);
    background-color: transparent;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color .1s;

}

.container .qr-login * {

    pointer-events: none;

}

.container .qr-login svg {

    width: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);

}

.container .qr-login:hover {

    background-color: var(--qr-login-hover);

}

.container .qr-login:active {

    background-color: var(--qr-login-active);

}

.container .qr-login .tooltip {

    white-space: nowrap;
    padding: 8px 14px;
    background-color: var(--tooltip-background);
    color: var(--tooltip-text);
    width: auto;
    font-size: 12px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(5px);
    top: calc(100% + 5px);
    border-radius: 7px;
    opacity: 0;
    transition: opacity .2s, transform .2s;
    pointer-events: none;

}
.container .qr-login:hover .tooltip {

    transform: translateX(-50%) translateY(0px);
    opacity: 1;

}

.container .qrcode {

    margin: -16px 0px 0px 0px;
    background-color: var(--qr-login-background);
    width: 220px;
    height: 220px;
    padding: 24px;
    box-sizing: border-box;
    border-radius: 15px;
    border: 3px solid var(--qr-login-border);
    box-sizing: border-box;
    position: relative;
    overflow: hidden;

}

.container .qrcode img {

    width: 100%;
    user-select: none;
    pointer-events: none;

}

.container .qrcode .qrloader {

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);

}

.container .qrcode svg {


    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    user-select: none;
    pointer-events: none;
    transform-origin: center center;

}

.tfa {

    display: flex;
    justify-content: space-between;
    column-gap: 10px;

}

.tfa-code {

    padding: 0px;

}

.tfa-code input {

    padding: 16px 0px!important;
    text-align: center;
    font-size: 18px;

}

.container .profile {

    border: 2px solid var(--profile-border);
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 6px;
    padding: 2px 3px;
    border-radius: 10px;
    margin: -28px 0px 24px 0px;
    user-select: none;

}

.container .profile img {

    width: 16px;
    height: 16px;
    border-radius: 50%;

}

.container .profile .email {

    font-size: 12px;
    padding: 0px 3px 0px 0px;
    color: var(--text-secondary);
    font-weight: 500;

}

.container .permissions {

    margin: 10px 0px 14px 0px;
    width: 310px;

}

.container .permissions .label {

    font-size: 13px;
    font-weight: 600;
    color: var(--text-tertiary);
    margin: 0px 0px -5px 0px;
    text-align: left;

}

.container .permissions .list {

    width: 100%;
    margin: 15px 0px 0px 0px;
    max-height: 265px;
    padding: 0px 10px 0px 0px;
    box-sizing: border-box;
    overflow: auto;
    min-height: 210px;

}

.container .permissions .list::-webkit-scrollbar {

    background: transparent;
    width: 0px;

}
      
.container .permissions .list {

    scrollbar-width: none;
    -ms-overflow-style: none;

}

.container .permissions .list.overflow {

    mask-image: linear-gradient(0deg, transparent 0%, rgba(0,0,0,1) 10%);
    -webkit-mask-image: linear-gradient(0deg, transparent 0%, rgba(0,0,0,1) 10%)

}

.container .permissions .list .permission {

    width: 100%;
    display: flex;
    column-gap: 10px;
    padding: 10px 0px;
    align-items: stretch;
    justify-content: stretch;
    flex-direction: row;
    user-select: none;

}

.container .permissions .list .permission * {

    pointer-events: none;

}

.container .permissions .list .permission:hover {

    cursor: pointer;

}

.container .permissions .list .permission:last-child {

    margin: 0px 0px 0px 0px;

}

.container .permissions .list .permission .icon {

    min-width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

}

.container .permissions .list .permission .icon svg {

    width: 21px;
    height: 21px;

}

.container .permissions .list .permission .label {

    font-weight: 400;
    font-size: 14px;
    color: var(--text-primary);
    line-height: 22px;
    margin: 0px;
    text-align: left;

}

.container .button-tray {

    display: flex;
    justify-content: center;
    column-gap: 10px;
    align-items: center;
    flex-direction: row-reverse;
    max-width: 100%;

}

.container .button-tray .button {

    flex: 1;
    min-width: 0px;

}

.no-spacing {

    margin: 0px 0px 0px 0px!important;

}

@media (max-width: 500px){

    .container .logo {

        position: absolute;
        top: 0px;
        transform: translateY(-100%);

    }

    .container .qr-login {

        display: none;

    }

}



.transition-enter {
    opacity: 0;
    transform: translateY(30px);
}

.transition-enter-active {
    opacity: 1;
    transform: translateY(0px);
    transition: transform .3s, opacity .3s;
    transition-delay: .05s;
    pointer-events: none;
}

.transition-exit {
    opacity: 1;
    transform: translateY(0px);
}

.transition-exit-active {
    opacity: 0;
    transform: translateY(-30px);
    transition: transform .15s, opacity .15s;
    pointer-events: none;
}


.qrcode .transition-enter {
    opacity: 0;
    transform: translate(-50%,-50%) translateY(40px)!important;
}

.qrcode .transition-enter-active {
    opacity: 1;
    transform: translate(-50%,-50%) translateY(0px)!important;
    transition: transform .3s, opacity .3s;
    transition-delay: .05s;
    pointer-events: none;
    transition-delay: .15s;
}

.qrcode .transition-exit {
    opacity: 1;
    transform: translate(-50%,-50%) translateY(0px)!important;
}

.qrcode .transition-exit-active {
    opacity: 0;
    transform: translate(-50%,-50%) translateY(-40px)!important;
    transition: transform .3s, opacity .3s;
    pointer-events: none;
}

.qrcode .qrloader {
    transform: translate(-50%,-50%) translateY(0px)!important;
}

.page-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}