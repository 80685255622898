.wrapper {

    position: relative;
    padding: 14px 12px;
    box-sizing: border-box;
    margin-right: -14px;
    border-radius: 50%;
    transition: background-color .25s;
}

.wrapper:hover {

    cursor: pointer;
    background-color: rgba(0,0,0,0.1);

}

.wrapper:active {

    background-color: rgba(0,0,0,0.15);

}

.wrapper * {

    pointer-events: none;

}

.wrapper .container {

    width: 100%;
    height: 100%;
    position: relative;

}

.wrapper .container div {

    position: absolute;
    width: 100%;
    height: 2px;
    background-color: black;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    transition: width .25s;

}

.wrapper .container::before {

    content: '';
    width: 100%;
    height: 2px;
    background-color: black;
    display: block;
    position: absolute;
    left: 50%;
    top: 0px;
    transform: translateX(-50%);
    transform-origin: left;
    transition: transform .25s, top .25s;


}

.wrapper .container::after {

    content: '';
    width: 100%;
    height: 2px;
    background-color: black;
    display: block;
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%) translateY(-100%);
    transform-origin: left;
    transition: transform .25s, top .25s;

}

.active .container div {

    width: 0px;

}

.active .container::after {

    top: 50%;
    transform: rotateZ(-45deg) translateX(-50%);

}

.active .container::before {

    top: 50%;
    transform: rotateZ(45deg) translateX(-50%) translateY(0px);

}