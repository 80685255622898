.wrapper {
    font-size: 0px;
}

.item {

    padding: 34px 30px;
    box-sizing: border-box;
    position: relative;

}

.item .header {

    display: flex;
    column-gap: 20px;
    align-items: center;

}

.item img {

    width: 50px;
    height: 50px;
    object-fit: contain;
    border-radius: 5px;

}

.item::after {

    content: '';
    width: calc(100% - 60px);
    height: 1.5px;
    background-color: var(--border-primary);
    position: absolute;
    left: 30px;
    bottom: 0px;
    transform: translateY(50%);

}

.wrapper:last-child .item::after {

    display: none;

}

.label {

    font-size: 17px;
    font-weight: 600;
    margin-bottom: 5px;
    color: var(--text-primary);
    position: relative;

}

.value {

    font-size: 14px;
    font-weight: 400;
    color: var(--text-secondary);
    line-height: 20px;

}

.divider {

    padding: 0px 4px;
    font-weight: 600;

}

.details {

    font-size: 14px;
    padding: 0px 0px 0px 70px;
    margin-top: 15px;

}

.details .title {

    font-size: 13px;
    font-weight: 600;
    color: var(--text-tertiary);
    text-align: left;
    margin: 0px 0px 7px 0px;

}

.permission {

    width: 100%;
    display: flex;
    column-gap: 10px;
    padding: 7px 0px;
    align-items: stretch;
    justify-content: stretch;
    flex-direction: row;
    user-select: none;

}

.permission * {

    pointer-events: none;

}

.permission:hover {

    cursor: pointer;

}

.permission:last-child {

    margin: 0px 0px 0px 0px;

}

.permission .icon {

    width: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

}

.permission .icon svg {

    width: 100%

}

.permission .label {

    font-weight: 400;
    font-size: 14px;
    color: var(--text-primary);
    line-height: 22px;
    margin: 0px;
    text-align: left;

}

.disconnect {

    margin: 17px 0px 0px auto;

}
.permission:hover .label {

    text-decoration: underline;
    text-decoration-thickness: 2px;

}

.verified {

    position: absolute;
    display: inline-block;
    width: 16px;
    height: 16px;
    background-color: var(--tint);
    border-radius: 50%;
    stroke: #fff;
    stroke-width: 2.5;
    margin: 0px 0px 0px 7px;
    top: 50%;
    transform: translateY(-50%);

}

.verified svg {

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 14px;
    margin: 0px 0px 0px -.12px

}