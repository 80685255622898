.loader {

    position: relative;

}

.loader svg {

    width: 100%;
    height: 100%;
    animation: loader-rotate 1.5s linear infinite;
    position: absolute;
    left: 50%;
    top: 50%;

}

.loader svg circle {

    stroke-linecap: round;
    animation: loader-dash 1.5s ease-in-out infinite;

}

@keyframes loader-rotate {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@keyframes loader-dash {
    0% {
        stroke-dasharray: 0, 150;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -125;
    }
}