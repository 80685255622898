.checkbox-wrapper {

    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

}

.checkbox {

    display: block;
    width: 20px;
    height: 20px;
    border: 0px solid transparent;
    outline: none;
    background-color: var(--checkbox-background);
    position: relative;
    border-radius: 8px;
    cursor: pointer;
    border: 2px solid var(--checkbox-border)

}

.checkbox input {

    width: calc(100% + 4px);
    height: calc(100% + 4px);
    position: absolute;
    left: -2px;
    top: -2px; 
    opacity: 0;
    margin: 0px;
    padding: 0px;

}

.checkbox svg {

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    stroke: var(--checkbox-enabled);
    width: 14px;
    display: none;
    pointer-events: none;
    user-select: none;
    stroke-width: 3;

}

.label {

    padding: 0px 0px 0px 6px;
    user-select: none;
    pointer-events: none;

}

.checked .checkbox {

    border: 2px solid var(--checkbox-enabled);

}

.checked .checkbox svg {

    display: block;

}