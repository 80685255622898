/* rubik-300 - latin-ext_latin_hebrew_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    src: url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-300.woff') format('woff'), /* Modern Browsers */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-300.svg#Rubik') format('svg'); /* Legacy iOS */
  }
  
  /* rubik-regular - latin-ext_latin_hebrew_cyrillic-ext_cyrillic */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    src: url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-regular.svg#Rubik') format('svg'); /* Legacy iOS */
  }
  
  /* rubik-500 - latin-ext_latin_hebrew_cyrillic-ext_cyrillic */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    src: url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-500.woff') format('woff'), /* Modern Browsers */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-500.svg#Rubik') format('svg'); /* Legacy iOS */
  }
  
  /* rubik-600 - latin-ext_latin_hebrew_cyrillic-ext_cyrillic */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    src: url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-600.woff') format('woff'), /* Modern Browsers */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-600.svg#Rubik') format('svg'); /* Legacy iOS */
  }
  
  /* rubik-700 - latin-ext_latin_hebrew_cyrillic-ext_cyrillic */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    src: url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-700.woff') format('woff'), /* Modern Browsers */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-700.svg#Rubik') format('svg'); /* Legacy iOS */
  }
  
  /* rubik-800 - latin-ext_latin_hebrew_cyrillic-ext_cyrillic */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 800;
    src: url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-800.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-800.woff2') format('woff2'), /* Super Modern Browsers */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-800.woff') format('woff'), /* Modern Browsers */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-800.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-800.svg#Rubik') format('svg'); /* Legacy iOS */
  }
  
  /* rubik-900 - latin-ext_latin_hebrew_cyrillic-ext_cyrillic */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 900;
    src: url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-900.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-900.woff2') format('woff2'), /* Super Modern Browsers */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-900.woff') format('woff'), /* Modern Browsers */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-900.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-900.svg#Rubik') format('svg'); /* Legacy iOS */
  }
  
  /* rubik-300italic - latin-ext_latin_hebrew_cyrillic-ext_cyrillic */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 300;
    src: url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-300italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-300italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-300italic.woff') format('woff'), /* Modern Browsers */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-300italic.svg#Rubik') format('svg'); /* Legacy iOS */
  }
  
  /* rubik-italic - latin-ext_latin_hebrew_cyrillic-ext_cyrillic */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 400;
    src: url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-italic.woff') format('woff'), /* Modern Browsers */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-italic.svg#Rubik') format('svg'); /* Legacy iOS */
  }
  
  /* rubik-500italic - latin-ext_latin_hebrew_cyrillic-ext_cyrillic */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 500;
    src: url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-500italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-500italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-500italic.woff') format('woff'), /* Modern Browsers */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-500italic.svg#Rubik') format('svg'); /* Legacy iOS */
  }
  
  /* rubik-600italic - latin-ext_latin_hebrew_cyrillic-ext_cyrillic */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 600;
    src: url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-600italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-600italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-600italic.woff') format('woff'), /* Modern Browsers */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-600italic.svg#Rubik') format('svg'); /* Legacy iOS */
  }
  
  /* rubik-700italic - latin-ext_latin_hebrew_cyrillic-ext_cyrillic */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 700;
    src: url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-700italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-700italic.woff') format('woff'), /* Modern Browsers */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-700italic.svg#Rubik') format('svg'); /* Legacy iOS */
  }
  
  /* rubik-800italic - latin-ext_latin_hebrew_cyrillic-ext_cyrillic */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 800;
    src: url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-800italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-800italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-800italic.woff') format('woff'), /* Modern Browsers */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-800italic.svg#Rubik') format('svg'); /* Legacy iOS */
  }
  
  /* rubik-900italic - latin-ext_latin_hebrew_cyrillic-ext_cyrillic */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 900;
    src: url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-900italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-900italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-900italic.woff') format('woff'), /* Modern Browsers */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./rubik-v23-latin-ext_latin_hebrew_cyrillic-ext_cyrillic-900italic.svg#Rubik') format('svg'); /* Legacy iOS */
  }
  